import { useInView } from "react-intersection-observer";
import styles from "./AnimatedInView.module.css";

interface AnimatedInViewProps {
  children: React.ReactNode;
  threshold?: number;
  triggerOnce?: boolean;
  className?: string;
}

export const AnimatedInView: React.FC<AnimatedInViewProps> = ({
  children,
  threshold = 0.2,
  triggerOnce = true,
  className,
}) => {
  const { ref, inView } = useInView({
    threshold,
    triggerOnce,
  });

  return (
    <div
      ref={ref}
      className={`${styles.wrapper} ${className} ${
        inView ? styles.visible : ""
      }`}
    >
      {children}
    </div>
  );
};
