import React from "react";
import styles from "./SectionTitle.module.css";

interface SectionTitleProps {
  title: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
  return (
    <div className={styles.titleWrapper}>
      <svg
        width="100"
        height="100"
        version="1.1"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.titleIcon}
      >
        <circle
          cx="50"
          cy="50"
          r="42.865"
          fill="#0f0"
          stroke="#000"
          strokeLinecap="square"
          strokeWidth="14.27"
        />
      </svg>
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
};
