import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import {
  SectionTitle,
  RichTextContent,
  PropertiesBox,
  ImageWithSrcSet,
  SectionContainer,
  AnimatedInView,
} from "@/components";
import { generateProductAnchorId } from "@/utils";
import styles from "./GensetsSection.module.css";

export const GensetsSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { gensets_section, products_section } = data;
  const anchorId = generateProductAnchorId(
    products_section.grid_gensets.grid_gensets_link,
    products_section.products_section_title_h2
  );

  return (
    <SectionContainer id={anchorId} className={styles.section}>
      <SectionTitle title={gensets_section.gensets_section_title_h2} />
      <div className={styles.content}>
        <div className={styles.textContent}>
          <RichTextContent
            content={gensets_section.gensets_section_desc}
            className={styles.description}
          />
          <PropertiesBox content={gensets_section.gensets_section_props} />
        </div>
        <AnimatedInView className={styles.imageWrapper}>
          <ImageWithSrcSet
            image={gensets_section.gensets_section_img}
            alt={gensets_section.gensets_section_img_alt}
            className={styles.image}
          />
        </AnimatedInView>
      </div>
    </SectionContainer>
  );
};
