import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { getMenuItemId } from "@/utils/menuItems";
import { SectionTitle, SectionContainer } from "@/components";
import styles from "./TrustedBySection.module.css";

export const TrustedBySection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { trustedby_section } = data;

  const trustedByMenuItem = getMenuItemId(data.page_header.main_menu_items, 3);

  return (
    <SectionContainer
      id={trustedByMenuItem}
      className={styles.trustedbySection}
    >
      <SectionTitle title={trustedby_section.trustedby_section_title_h2} />

      {trustedby_section.trustedby_section_desc && (
        <p className={styles.description}>
          {trustedby_section.trustedby_section_desc}
        </p>
      )}

      <div className={styles.logosContainer}>
        <div className={styles.logosGrid}>
          {trustedby_section.customers.map((customer, index) => (
            <img
              key={index}
              src={
                customer.logo.type === "image/svg+xml"
                  ? customer.logo.url
                  : customer.logo.sizes.original ??
                    customer.logo.sizes.large ??
                    customer.logo.sizes.medium
              }
              alt={customer.name}
              title={customer.name}
              loading="eager"
              className={styles.logo}
            />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};
