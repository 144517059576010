export const i18n = {
  pageTitle: {
    cs: "Napište nám zprávu",
    en: "Get in Touch",
    uk: "Напишіть нам",
  },
  pageDescription: {
    cs: "Kontaktujte nás pro dotazy ohledně našich systémů UPS a řešení zálohování napájení. Vyplňte náš kontaktní formulář pro rychlou pomoc od našeho týmu odborníků.",
    en: "Contact us for inquiries about our UPS systems and power backup solutions. Fill out our contact form for prompt assistance from our expert team.",
    uk: "Зв'яжіться з нами для запитань щодо наших систем UPS та резервного живлення. Заповніть форму для швидкої допомоги від нашої команди.",
  },
  fields: {
    name: {
      cs: "Jméno",
      en: "Name",
      uk: "Ім'я",
    },
    email: {
      cs: "E-mail",
      en: "Email",
      uk: "Емейл",
    },
    phone: {
      cs: "Telefon",
      en: "Phone",
      uk: "Телефон",
    },
    message: {
      cs: "Zpráva",
      en: "Message",
      uk: "Повідомлення",
    },
    consent: {
      cs: "Souhlasím se zpracováním svých osobních údajů za účelem vyřízení mého dotazu.",
      en: "I consent to the processing of my personal data for the purpose of handling my inquiry.",
      uk: "Я даю згоду на обробку моїх персональних даних з метою обробки мого запиту.",
    },
    submit: {
      cs: "Odeslat zprávu",
      en: "Send Message",
      uk: "Надіслати повідомлення",
    },
  },
  responseMessages: {
    FORM_SUBMISSION_SUCCESS: {
      cs: "Formulář byl úspěšně odeslán.",
      en: "Form submitted successfully.",
      uk: "Форма успішно відправлена.",
    },
    FORM_SUBMISSION_FAILED_RECAPTCHA_LOW_SCORE: {
      cs: "Odeslání formuláře se nezdařilo z důvodu podezření na spam. Zkuste nás kontaktovat telefonicky nebo emailem.",
      en: "Form submission failed due to suspected spam. Please contact us by phone or email.",
      uk: "Форма не відправлена через потенційне спам-активність. Зверніться до нас за телефоном або електронною поштою.",
    },
    FORM_SUBMISSION_FAILED_RECAPTCHA_INVALID_TOKEN: {
      cs: "Odeslání se nezdařilo kvůli problému s ověřením. Zkuste to prosím později, nebo nás kontaktujte telefonicky či emailem.",
      en: "Form submission failed due to a verification problem. Please try again later or contact us by phone or email.",
      uk: "Форма не відправлена через проблему з перевіркою. Будь ласка, спробуйте знову заповнити форму або зверніться до нас за телефоном або електронною поштою.",
    },
    FORM_SUBMISSION_FAILED_UNKNOWN: {
      cs: "Odeslání formuláře se nezdařilo. Zkuste to prosím později, nebo nás kontaktujte telefonicky či emailem.",
      en: "Form submission failed. Please try again later or contact us by phone or email.",
      uk: "Форма не відправлена. Будь ласка, спробуйте знову заповнити форму або зверніться до нас за телефоном або електронною поштою.",
    },
  },
  dialogTitle: {
    FORM_SUBMISSION_SUCCESS: {
      cs: "Formulář byl odeslán",
      en: "Form submitted",
      uk: "Форма відправлена",
    },
    FORM_SUBMISSION_FAILED_RECAPTCHA_LOW_SCORE: {
      cs: "Odeslání formuláře se nezdařilo",
      en: "Form submission failed",
      uk: "Форма не відправлена",
    },
    FORM_SUBMISSION_FAILED_RECAPTCHA_INVALID_TOKEN: {
      cs: "Odeslání formuláře se nezdařilo",
      en: "Form submission failed",
      uk: "Форма не відправлена",
    },
    FORM_SUBMISSION_FAILED_UNKNOWN: {
      cs: "Odeslání formuláře se nezdařilo",
      en: "Form submission failed",
      uk: "Форма не відправлена",
    },
  },
  dialogCloseBtn: {
    cs: "Zavřít",
    en: "Close",
    uk: "Закрити",
  },
  dialogTryAgainBtn: {
    cs: "Zkusit znovu",
    en: "Try again",
    uk: "Спробувати знову",
  },
  dialogResetBtn: {
    cs: "Resetovat formulář",
    en: "Reset form",
    uk: "Скинути форму",
  },
  cookieConsent: {
    tabs: {
      consent: {
        cs: "Souhlas",
        en: "Consent",
        uk: "Згода",
      },
      recaptcha: {
        cs: "reCAPTCHA",
        en: "reCAPTCHA",
        uk: "reCAPTCHA",
      },
      cookies: {
        cs: "Cookies",
        en: "Cookies",
        uk: "Cookies",
      },
    },
    title: {
      cs: "Souhlas s cookies",
      en: "Cookie Consent",
      uk: "Згода на використання cookies",
    },
    message: {
      cs: "Tento kontaktní formulář pro ochranu před zneužitím a spamem používá službu reCAPTCHA, která pro své fungování vyžaduje soubory cookie. Pro odeslání formuláře je nutný váš souhlas s použitím těchto souborů.",
      en: "This contact form for protection against abuse and spam uses the reCAPTCHA service, which requires cookies to function. To submit the form, you must consent to the use of these cookies.",
      uk: "Цей контактний формуляр для захисту від зловживання та спаму використовує службу reCAPTCHA, яка вимагає файлів cookie для функціонування. Для відправки форми вам потрібен ваш згода на використання цих файлів cookie.",
    },
    declaration: {
      cs: "Kliknutím na “Souhlasím s cookies” potvrzujete svůj souhlas.",
      en: "By clicking “Accept cookies” you confirm your consent.",
      uk: "Натиснувши “Прийняти cookies” ви підтверджуєте свою згоду.",
    },
    acceptBtn: {
      cs: "Souhlasím s cookies",
      en: "Accept cookies",
      uk: "Прийняти cookies",
    },
    alternativeBtn: {
      cs: "Alternativní kontakt",
      en: "Alternative contact",
      uk: "Альтернативний контакт",
    },
  },
  recaptchaTab: {
    briefInfo: {
      cs: "Tento kontaktní formulář používá reCAPTCHA v3, která využívá soubory cookie k rozlišení mezi lidskými uživateli a automatizovanými boty. Tyto soubory cookie sledují vaši interakci s formulářem, ale neshromažďují žádné osobní údaje. ReCAPTCHA v3 pracuje neviditelně na pozadí - nebudete muset řešit žádné hádanky ani klikat na žádná políčka.",
      en: "This contact form uses reCAPTCHA v3, which places cookies to help distinguish between human users and automated bots. These cookies track your interaction with the form but don't collect any personal information. reCAPTCHA v3 works invisibly in the background - you won't need to solve any puzzles or click any boxes.",
      uk: "Ця контактна форма використовує reCAPTCHA v3, яка використовує файли cookie, щоб розрізняти людей-користувачів та автоматизованих ботів. Ці файли cookie відстежують вашу взаємодію з формою, але не збирають особисту інформацію. ReCAPTCHA v3 працює непомітно у фоновому режимі - вам не потрібно розв'язувати головоломки чи клікати на будь-які поля.",
    },
    whatIs: {
      title: {
        cs: "Co je reCAPTCHA v3?",
        en: "What is reCAPTCHA v3?",
        uk: "Що таке reCAPTCHA v3?",
      },
      content: {
        cs: "ReCAPTCHA v3 je pokročilá bezpečnostní služba Google, která chrání webové stránky před spamem a zneužitím. Na rozdíl od starších verzí, které vyžadovaly řešení hádanek nebo klikání na zaškrtávací políčka, verze 3 pracuje zcela na pozadí aniž by vás rušila. Služba využívá pokročilé algoritmy analýzy rizik ke sledování interakcí uživatelů a přiřazuje každému návštěvníkovi skóre důvěryhodnosti mezi 0,0 a 1,0, kde 1,0 představuje přirozené lidské chování a nižší skóre naznačují potenciální automatizovanou nebo podezřelou aktivitu.",
        en: "ReCAPTCHA v3 is Google's advanced security service that protects websites from spam and abuse. Unlike older versions that required users to solve puzzles or click checkboxes, version 3 works entirely in the background. It uses advanced risk analysis algorithms to monitor user interactions and assign a trust score between 0.0 and 1.0 to each visitor, where 1.0 represents natural human behavior and lower scores suggest potential automated or suspicious activity.",
        uk: "ReCAPTCHA v3 - це передова служба безпеки Google, яка захищає веб-сайти від спаму та зловживань. На відміну від старіших версій, які вимагали від користувачів розв'язування головоломок або натискання на прапорці, версія 3 працює повністю у фоновому режимі. Вона використовує передові алгоритми аналізу ризиків для моніторингу взаємодії користувачів і присвоює кожному відвідувачу оцінку довіри від 0,0 до 1,0, де 1,0 представляє природну поведінку людини, а нижчі оцінки вказують на потенційну автоматизовану або підозрілу активність.",
      },
    },
    whyUse: {
      title: {
        cs: "Proč používáme reCAPTCHA v3 pro kontaktní formulář?",
        en: "Why do we use reCAPTCHA v3 for contact form?",
        uk: "Чому ми використовуємо reCAPTCHA v3 для контактної форми?",
      },
      content: {
        cs: "Náš kontaktní formulář je chráněn reCAPTCHA v3, abychom zachovali kvalitu komunikace a chránili naše zdroje podpory. Bez takové ochrany se kontaktní formulář může stát cílem spamovacích robotů zahlcujících naše systémy falešnými zprávami, reklamou nebo škodlivým obsahem. ReCAPTCHA v3 nám pomáhá zajistit, aby se k nám mohly dostat zprávy od skutečných lidí. To nám umožňuje rychleji reagovat na Vaše dotazy a udržovat bezpečnost našich komunikačních kanálů.",
        en: "Our contact form is protected by reCAPTCHA v3 to maintain the quality of communications and protect our support resources. Without such protection, contact forms can become targets for spam bots that flood systems with fake messages, advertising, or malicious content. ReCAPTCHA v3 helps us ensure that real people can reach us while automatically filtering out automated submissions. This allows us to respond more quickly to genuine inquiries and maintain the security of our communication channels.",
        uk: "Наша контактна форма захищена reCAPTCHA v3 для підтримки якості комунікації та захисту наших ресурсів підтримки. Без такого захисту контактні форми можуть стати мішенню для спам-ботів, які заповнюють системи фальшивими повідомленнями, рекламою або шкідливим вмістом. ReCAPTCHA v3 допомагає нам гарантувати, що з нами можуть зв'язатися реальні люди, водночас автоматично фільтруючи автоматизовані подання. Це дозволяє нам швидше реагувати на справжні запити та підтримувати безпеку наших каналів зв'язку.",
      },
    },
    howUse: {
      title: {
        cs: "Jak reCAPTCHA v3 používá cookies?",
        en: "How does reCAPTCHA v3 use cookies?",
        uk: "Як reCAPTCHA v3 використовує файли cookie?",
      },
      content: {
        cs: "ReCAPTCHA v3 používá soubory cookie k analýze vzorců chování uživatelů a stanovení skóre důvěryhodnosti. Tyto soubory cookie sledují různé interakce, jako jsou pohyby myši, vzorce psaní a chování při procházení webu. Služba ukládá tato data do souborů cookie, jejichž platnost obvykle vyprší po 6 měsících. Shromážděné informace se používají výhradně pro bezpečnostní účely a pomáhají rozlišovat mezi přirozeným lidským chováním a automatizovanou činností robotů. I když soubory cookie shromažďují údaje o chování, neshromažďují žádné osobní údaje a veškeré zpracování dat je v souladu s předpisy o ochraně osobních údajů. Soubory cookie jsou nezbytné pro efektivní fungování bezpečnostní služby a kontaktního formuláře a nelze je zakázat.",
        en: "reCAPTCHA v3 uses cookies to analyze patterns in user behavior and establish a trust score. These cookies track various interactions such as mouse movements, typing patterns, and browsing behavior on the site. The service stores this data in cookies that typically expire after 6 months. The collected information is used solely for security purposes and helps distinguish between natural human behavior and automated bot activity. While the cookies do collect behavioral data, they don't gather any personally identifiable information, and all data processing complies with privacy regulations. The cookies are essential for the security service to function effectively and cannot be disabled while using the contact form.",
        uk: "ReCAPTCHA v3 використовує файли cookie для аналізу шаблонів поведінки користувачів і встановлення оцінки довіри. Ці файли cookie відстежують різні взаємодії, такі як рухи миші, шаблони набору тексту та поведінку перегляду на сайті. Служба зберігає ці дані у файлах cookie, термін дії яких зазвичай закінчується через 6 місяців. Зібрана інформація використовується виключно для цілей безпеки та допомагає розрізняти природну поведінку людини та активність автоматизованих ботів. Хоча файли cookie збирають поведінкові дані, вони не збирають жодної особистої інформації, а вся обробка даних відповідає правилам конфіденційності. Файли cookie необхідні для ефективного функціонування служби безпеки і не можуть бути вимкнені при використанні контактної форми.",
      },
    },
  },
  cookieTab: {
    briefIntro: {
      cs: "Pro fungování kontaktního formuláře jsou službou Google reCAPTCHA v3 nastaveny následující soubory cookie:",
      en: "The following cookies are set by Google reCAPTCHA v3 for the contact form to function:",
      uk: "Для роботи контактної форми встановлені наступні файли cookie від Google reCAPTCHA v3:",
    },
    category: {
      cs: "Kategorie",
      en: "Category",
      uk: "Категорія",
    },
    publisher: {
      cs: "Vydavatel",
      en: "Publisher",
      uk: "Видавець",
    },
    type: {
      cs: "Typ",
      en: "Type",
      uk: "Тип",
    },
    validityPeriod: {
      cs: "Obvyklá doba platnosti",
      en: "Usual validity period",
      uk: "Звичайний термін дії",
    },
    note: {
      cs: "Konkrétní doba expirace se může lišit. Můžete ji najít pomocí DevTools vašeho prohlížeče.",
      en: "The specific expiration time may vary. You can find it using your browser's DevTools.",
      uk: "Конкретний термін дії може відрізнятися. Ви можете знайти його за допомогою DevTools вашого браузера.",
    },
    linkPolicy: {
      text: {
        cs: "Zásady ochrany soukromí Google",
        en: "Google Privacy Policy",
        uk: "Політика конфіденційності Google",
      },
      url: {
        cs: "https://policies.google.com/privacy?hl=cs",
        en: "https://policies.google.com/privacy?hl=en",
        uk: "https://policies.google.com/privacy?hl=uk",
      },
    },
    linkTerms: {
      text: {
        cs: "Smluvní podmínky Google",
        en: "Google Terms of Service",
        uk: "Загальні положення та умови Google",
      },
      url: {
        cs: "https://policies.google.com/terms?hl=cs",
        en: "https://policies.google.com/terms?hl=en",
        uk: "https://policies.google.com/terms?hl=uk",
      },
    },
    removeCookies: {
      cs: "Cookies můžete kdykoli smazat prostřednictvím nastavení svého prohlížeče. Většina moderních prohlížečů umožňuje spravovat cookies, blokovat je nebo je automaticky mazat při zavření okna. Podrobné informace o správě cookies naleznete v nápovědě vašeho prohlížeče.",
      en: "You can delete cookies at any time through your browser settings. Most modern browsers allow you to manage cookies, block them, or automatically delete them when you close the window. For detailed information on managing cookies, refer to your browser's help.",
      uk: "Ви можете видаляти файли cookie в будь-який момент через налаштування браузера. Більшість сучасних браузерів дозволяють керувати файлами cookie, блокувати їх або автоматично видаляти при закритті вікна. Докладну інформацію про керування файлами cookie див. у довідці вашого браузера.",
    },
  },
};
