import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import Header from "./Header";
import { HomepageData } from "@/types/homepage";
import { removeDiacritics } from "@/utils/menuItems";

export const HomepageContext = createContext<{
  data: HomepageData | null;
  language: "cs" | "en" | "uk";
  setLanguage: (lang: "cs" | "en" | "uk") => void;
}>({
  data: null,
  language: "cs",
  setLanguage: () => {},
});

const ConnectedHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [homepageData, setHomepageData] = useState<HomepageData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState<"cs" | "en" | "uk">(() => {
    return (
      (localStorage.getItem("preferredLanguage") as "cs" | "en" | "uk") || "cs"
    );
  });

  const handleLanguageChange = (newLanguage: "cs" | "en" | "uk") => {
    setLanguage(newLanguage);
    localStorage.setItem("preferredLanguage", newLanguage);
  };

  useEffect(() => {
    document.documentElement.lang = language;

    const fetchHeaderData = async () => {
      try {
        const response = await axios.get(
          `https://upstechnology.cz/cms/wp-json/ups-technology-headless-api/v1/homepage?lang=${language}`
        );
        setHomepageData(response.data);
      } catch (error) {
        console.error("Error fetching homepage data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHeaderData();
  }, [language]);

  // Parse the HTML string to extract menu items
  const parseMenuItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const items = Array.from(doc.querySelectorAll("li"))
      .slice(0, 6)
      .map((li, index) => ({
        id: index,
        title: li.textContent || "",
        link: removeDiacritics((li.textContent || "").toLowerCase()),
      }));
    return items;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <HomepageContext.Provider
      value={{
        data: homepageData,
        language,
        setLanguage: handleLanguageChange,
      }}
    >
      {homepageData && (
        <Header
          logoUrl={
            homepageData.page_header.company_logo.type === "image/svg+xml"
              ? homepageData.page_header.company_logo.url
              : homepageData.page_header.company_logo.sizes.original ??
                homepageData.page_header.company_logo.sizes.large ??
                homepageData.page_header.company_logo.sizes.medium ??
                ""
          }
          logoAlt={homepageData.page_header.company_logo_alt}
          menuItems={parseMenuItems(homepageData.page_header.main_menu_items)}
          currentLanguage={language}
          onLanguageChange={handleLanguageChange}
        />
      )}
      {children}
    </HomepageContext.Provider>
  );
};

export default ConnectedHeader;
