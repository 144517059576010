import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "./JobList.module.css";

interface JobPosition {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  slug: string;
}

const JobList: React.FC = () => {
  const [jobPositions, setJobPositions] = useState<JobPosition[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(
          "https://upstechnology.cz/cms/wp-json/wp/v2/jobs"
        );
        setJobPositions(response.data);
      } catch (error) {
        console.error("Error fetching job positions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobPositions();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ul className={styles.jobList}>
      {jobPositions.map((job) => (
        <li key={job.id} className={styles.jobItem}>
          <Link to={`/career/${job.slug}`} className={styles.jobLink}>
            {job.title.rendered}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default JobList;
