import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { SectionContainer, SectionTitle, RichTextContent } from "@/components";
import { getMenuItemId } from "@/utils";
import styles from "./ServiceSection.module.css";

export const ServiceSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { service_section } = data;
  const serviceMenuItem = getMenuItemId(data.page_header.main_menu_items, 2);

  return (
    <SectionContainer id={serviceMenuItem} className={styles.section}>
      <SectionTitle title={service_section.service_section_title_h2} />

      <div className={styles.content}>
        <div className={styles.textColumn}>
          <RichTextContent
            content={service_section.service_section_desc_one}
            className={styles.description}
          />
        </div>
        <div className={styles.textColumn}>
          <RichTextContent
            content={service_section.service_section_desc_two}
            className={styles.description}
          />
          <RichTextContent
            content={service_section.service_section_desc_three}
            className={styles.description}
          />
        </div>
      </div>
    </SectionContainer>
  );
};
