import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import {
  SectionTitle,
  RichTextContent,
  PropertiesBox,
  ImageWithSrcSet,
  SectionContainer,
  AnimatedInView,
} from "@/components";
import styles from "./UpsSection.module.css";
import { generateProductAnchorId } from "@/utils";

export const UpsSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { ups_section, products_section } = data;
  const anchorId = generateProductAnchorId(
    products_section.grid_ups.grid_ups_link,
    products_section.products_section_title_h2
  );

  return (
    <SectionContainer id={anchorId} className={styles.section}>
      <SectionTitle title={ups_section.ups_section_title_h2} />
      <div className={styles.content}>
        <AnimatedInView className={styles.imageWrapper}>
          <ImageWithSrcSet
            image={ups_section.ups_section_img}
            alt={ups_section.ups_section_img_alt}
            className={styles.image}
          />
        </AnimatedInView>
        <div className={styles.textContent}>
          <RichTextContent
            content={ups_section.ups_section_desc}
            className={styles.description}
          />
          <PropertiesBox content={ups_section.ups_section_props} />
        </div>
      </div>
    </SectionContainer>
  );
};
