import { removeDiacritics } from "./menuItems";

export const generateProductAnchorId = (
  linkValue: string | null | undefined,
  sectionTitle: string
): string => {
  if (!linkValue) return "";

  return removeDiacritics(`${linkValue}-${sectionTitle}`);
};
