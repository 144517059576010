import React from "react";
import { SEO } from "@/components";
import styles from "./PageContainer.module.css";

interface PageContainerProps {
  className?: string;
  title: string;
  description: string;
  type?: string;
  children: React.ReactNode;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  className,
  title,
  description,
  type = "website",
  children,
}) => {
  return (
    <div className={className}>
      <SEO title={title} description={description} type={type} />
      <div className={`page container`}>
        <h1 className={styles.title}>{title}</h1>
        {children}
      </div>
    </div>
  );
};
