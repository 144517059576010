export const cookieList = [
  {
    title: "AEC",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "6 months",
  },
  {
    title: "APISID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "HSID",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "NID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "6 months",
  },
  {
    title: "SAPISID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "SEARCH_SAMESITE",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "6 months",
  },
  {
    title: "SID",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "SIDCC",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
  {
    title: "SSID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "_GRECAPTCHA",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "6 months",
  },
  {
    title: "__Secure-1PAPISID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "__Secure-1PSID",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "__Secure-1PSIDCC",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
  {
    title: "__Secure-1PSIDTS",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
  {
    title: "__Secure-3PAPISID",
    category: "Preferences",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "__Secure-3PSID",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "2 years",
  },
  {
    title: "__Secure-3PSIDCC",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
  {
    title: "__Secure-3PSIDTS",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
  {
    title: "__Secure-ENID",
    category: "Security",
    publisher: "Google Ireland Limited",
    type: "Third-party cookie",
    duration: "1 year",
  },
];
