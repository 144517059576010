import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { DialogButton } from "./DialogButton";

export enum FormSubmissionStatusCode {
  FORM_SUBMISSION_SUCCESS = "FORM_SUBMISSION_SUCCESS",
  FORM_SUBMISSION_FAILED_RECAPTCHA_LOW_SCORE = "FORM_SUBMISSION_FAILED_RECAPTCHA_LOW_SCORE",
  FORM_SUBMISSION_FAILED_RECAPTCHA_INVALID_TOKEN = "FORM_SUBMISSION_FAILED_RECAPTCHA_INVALID_TOKEN",
  FORM_SUBMISSION_FAILED_UNKNOWN = "FORM_SUBMISSION_FAILED_UNKNOWN",
}

interface SubmissionResultDialogProps {
  open: boolean;
  onClose: () => void;
  onReset: () => void;
  submissionResult: {
    success: boolean;
    statusCode: FormSubmissionStatusCode;
  } | null;
  currentLang: string;
  i18n: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SubmissionResultDialog: React.FC<SubmissionResultDialogProps> = ({
  open,
  onClose,
  onReset,
  submissionResult,
  currentLang,
  i18n,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onReset}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          color:
            submissionResult?.statusCode ===
            FormSubmissionStatusCode.FORM_SUBMISSION_SUCCESS
              ? "var(--color-primary-dark)"
              : "var(--color-text-alert)",
          "@media (max-width: 400px)": {
            fontSize: "1.25rem",
          },
        }}
      >
        {submissionResult &&
          i18n.dialogTitle[submissionResult.statusCode][currentLang]}
        <IconButton
          aria-label="close"
          onClick={onReset}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {submissionResult &&
          i18n.responseMessages[submissionResult.statusCode][currentLang]}
      </DialogContent>

      <DialogActions>
        {submissionResult &&
          ([
            FormSubmissionStatusCode.FORM_SUBMISSION_SUCCESS,
            FormSubmissionStatusCode.FORM_SUBMISSION_FAILED_RECAPTCHA_LOW_SCORE,
          ].includes(submissionResult.statusCode) ? (
            <DialogButton onClick={onReset}>
              {i18n.dialogCloseBtn[currentLang]}
            </DialogButton>
          ) : (
            <>
              <DialogButton onClick={onClose}>
                {i18n.dialogTryAgainBtn[currentLang]}
              </DialogButton>
              <DialogButton onClick={onReset}>
                {i18n.dialogResetBtn[currentLang]}
              </DialogButton>
            </>
          ))}
      </DialogActions>
    </Dialog>
  );
};
