import { createTheme } from "@mui/material";

export const createContactTheme = (fontSize: number) =>
  createTheme({
    typography: {
      fontFamily: "Montserrat, sans-serif",
      fontSize,
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize,
            fontFamily: "Montserrat, sans-serif",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--color-primary-dark) !important",
            },
            "& .MuiOutlinedInput-notchedOutline legend span": {
              paddingRight: 16,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "#00e200",
              transform: "translate(14px, -18px) scale(0.75)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -18px) scale(0.75)",
            },
            "@media (max-width: 1920px)": {
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(16px, -12px) scale(0.75)",
              },
            },
            "@media (max-width: 480px)": {
              "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(18px, -9px) scale(0.75)",
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: "baseline",
          },
          label: {
            fontSize: "var(--base-font-size)",
            lineHeight: "var(--base-line-height)",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            top: "0.625rem",
            "&.Mui-checked": {
              color: "var(--color-primary-dark)",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "var(--color-background-dark)",
            color: "var(--color-text-light)",
            padding: "15px 30px",
            borderRadius: "8px",
            fontSize: "var(--base-font-size)",
            textTransform: "none",
            boxShadow: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "var(--color-background-dark)",
              color: "var(--color-primary)",
              transform: "translateY(-2px)",
              boxShadow: "0 4px 12px rgba(0, 255, 0, 0.2)",
            },
          },
        },
      },
    },
  });
