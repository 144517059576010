import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { getMenuItemId } from "@/utils";
import { Link } from "react-router-dom";
import { ROUTES } from "@/config/routes";
import styles from "./ContactSection.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const ContactSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { page_footer } = data;

  const contactMenuItem = getMenuItemId(data.page_header.main_menu_items, 5);

  return (
    <footer id={contactMenuItem} className={styles.footer}>
      <div className="container">
        <div className={styles.headerRow}>
          <img
            src={
              page_footer.contact_company_logo.contact_company_logo_img.type ===
              "image/svg+xml"
                ? page_footer.contact_company_logo.contact_company_logo_img.url
                : page_footer.contact_company_logo.contact_company_logo_img
                    .sizes.original ??
                  page_footer.contact_company_logo.contact_company_logo_img
                    .sizes.large ??
                  page_footer.contact_company_logo.contact_company_logo_img
                    .sizes.medium
            }
            alt={page_footer.contact_company_logo.contact_company_logo_alt}
            loading="eager"
            className={styles.logo}
          />
        </div>

        <div className={styles.address}>
          <strong>
            {page_footer.contact_company_office.contact_company_name}
          </strong>
          <p>{page_footer.contact_company_office.contact_company_address}</p>
        </div>

        <div className={styles.contactGrid}>
          <div className={styles.contactColumn}>
            <div className={styles.contactItem}>
              <PhoneIcon className={styles.icon} />
              <p>{page_footer.contact_company_phones.contact_company_mobile}</p>
            </div>
            <div className={styles.contactItem}>
              <PhoneIcon className={styles.icon} />
              <p>
                {page_footer.contact_company_phones.contact_company_landline}
              </p>
            </div>
          </div>

          <div className={styles.contactColumn}>
            <div className={styles.contactItem}>
              <EmailIcon className={styles.icon} />
              <a
                href={`mailto:${page_footer.contact_company_online.contact_company_email}`}
              >
                {page_footer.contact_company_online.contact_company_email}
              </a>
            </div>
            <div className={styles.contactItem}>
              <LanguageIcon className={styles.icon} />
              <a
                href={
                  page_footer.contact_company_online.contact_company_website
                    .contact_company_website_url
                }
              >
                {
                  page_footer.contact_company_online.contact_company_website
                    .contact_company_website_title
                }
              </a>
            </div>
            <div className={styles.contactItem}>
              <LinkedInIcon className={styles.icon} />
              <a
                href={
                  page_footer.contact_company_online.contact_company_socials
                    .contact_company_linkedin_url
                }
                target={
                  page_footer.contact_company_online.contact_company_socials.contact_company_linkedin_target.includes(
                    "ano"
                  )
                    ? "_blank"
                    : undefined
                }
                rel={
                  page_footer.contact_company_online.contact_company_socials.contact_company_linkedin_target.includes(
                    "ano"
                  )
                    ? "noopener noreferrer"
                    : undefined
                }
              >
                LinkedIn
              </a>
            </div>
          </div>

          <div className={styles.contactColumn}>
            <h2 className={styles.formTitle}>
              {page_footer.contact_form.contact_form_h2}
            </h2>
            <Link
              to={ROUTES.CONTACT}
              className={styles.formLink}
              onClick={() => window.scrollTo(0, 0)}
            >
              {page_footer.contact_form.contact_form_text}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ContactSection;
