import type { Image } from "@/types/homepage";

export const getImageSrcSet = (image: Image): string => {
  if (image.type === "image/svg+xml") return "";

  const sizes = image.sizes;
  const srcSetParts = [];

  const aspectRatio = image.dimensions.width / image.dimensions.height;

  if (sizes.small)
    srcSetParts.push(`${sizes.small} ${Math.round(300 * aspectRatio)}w`);
  if (sizes.medium)
    srcSetParts.push(`${sizes.medium} ${Math.round(768 * aspectRatio)}w`);
  if (sizes.large)
    srcSetParts.push(`${sizes.large} ${Math.round(1024 * aspectRatio)}w`);
  if (sizes.xl)
    srcSetParts.push(`${sizes.xl} ${Math.round(1536 * aspectRatio)}w`);
  if (sizes.hd)
    srcSetParts.push(`${sizes.hd} ${Math.round(2048 * aspectRatio)}w`);
  if (sizes.full)
    srcSetParts.push(`${sizes.full} ${Math.round(2560 * aspectRatio)}w`);
  if (sizes.original)
    srcSetParts.push(`${sizes.original} ${image.dimensions.width}w`);

  return srcSetParts.join(", ");
};
