import React, { useContext, useEffect, useState, useRef } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import axios from "axios";
import { PageContainer } from "@/components";
import {
  Grid,
  TextField,
  Button,
  Container,
  Paper,
  ThemeProvider,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { createContactTheme } from "./contactTheme";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { BASE_PATH, ROUTES } from "@/config/routes";
import { i18n } from "./contact.i18n";
import { ConsentDialog } from "./components/ConsentDialog";
import {
  FormSubmissionStatusCode,
  SubmissionResultDialog,
} from "./components/SubmissionResultDialog";

interface SubmissionResultProps {
  success: boolean;
  statusCode: FormSubmissionStatusCode;
}

const ContactPage: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { language } = useContext(HomepageContext);
  const currentLang = (language || "cs") as keyof typeof i18n.pageTitle;
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);
  const [showCookieDialog, setShowCookieDialog] = useState<boolean>(true);
  const [consentChecked, setConsentChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [fontSize, setFontSize] = useState(() => {
    const root = document.documentElement;
    const rootFontSize = parseFloat(getComputedStyle(root).fontSize);
    const baseFontSizeRem = parseFloat(
      getComputedStyle(root).getPropertyValue("--base-font-size")
    );
    return baseFontSizeRem * rootFontSize;
  });

  const getPageTitle = () => i18n.pageTitle[currentLang];
  const getPageDescription = () => i18n.pageDescription[currentLang];
  const getFieldLabel = (field: keyof typeof i18n.fields) =>
    i18n.fields[field][currentLang];

  const [submissionResult, setSubmissionResult] =
    useState<SubmissionResultProps | null>(null);

  useEffect(() => {
    // Handle font size updates
    const updateFontSize = () => {
      const root = document.documentElement;
      const rootFontSize = parseFloat(getComputedStyle(root).fontSize);
      const baseFontSizeRem = parseFloat(
        getComputedStyle(root).getPropertyValue("--base-font-size")
      );
      setFontSize(baseFontSizeRem * rootFontSize);
    };

    // Handle reCAPTCHA script
    const existingRecaptchaScript = document.querySelector(
      'script[src*="recaptcha"]'
    );
    if (existingRecaptchaScript) {
      existingRecaptchaScript.remove();
    }

    window.addEventListener("resize", updateFontSize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  const theme = createContactTheme(fontSize);

  const getRecaptchaKey = () => {
    const key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    if (!key) {
      throw new Error("REACT_APP_RECAPTCHA_SITE_KEY is not defined");
    }
    return key;
  };

  useEffect(() => {
    if (cookieConsent) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  }, [cookieConsent]);

  const handleCookieConsent = () => {
    setCookieConsent(true);
    setShowCookieDialog(false);
  };

  const handleAlternativeContact = () => {
    setShowCookieDialog(false);
    sessionStorage.setItem("scrollToBottom", "true");
    window.location.href = `${BASE_PATH}${ROUTES.HOME}`;
  };

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentChecked(event.target.checked);
  };

  const handleRecaptchaChange = (token: string) => {
    setRecaptchaToken(token);
  };

  const handleCloseDialog = () => {
    setConsentChecked(false);
    setSubmissionResult(null);
  };

  const handleResetForm = () => {
    formRef.current?.reset();
    handleCloseDialog();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmissionResult(null);

    const formData = new FormData(form);

    const honeypot = formData.get("website");
    if (honeypot) {
      setIsSubmitting(false);
      return;
    }

    // Log raw form data
    // console.log("Raw form data:", Object.fromEntries(formData));

    // Log complete request payload
    const requestPayload = {
      ...Object.fromEntries(formData),
      "g-recaptcha-response": recaptchaToken,
    };
    // console.log("Complete request payload:", requestPayload);

    try {
      const response = await axios.post(
        "https://upstechnology.cz/cms/wp-json/ups-technology-headless-api/v1/contact",
        requestPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log("API Response:", response);
      console.log("Submission reason:", response.data.data.reason);

      setSubmissionResult({
        success: response.data.success,
        statusCode: response.data.data.statusCode,
      });
    } catch (error: any) {
      setRefreshReCaptcha(!refreshReCaptcha);
      console.log("Error submitting form:", error);

      setSubmissionResult({
        success: false,
        statusCode: FormSubmissionStatusCode.FORM_SUBMISSION_FAILED_UNKNOWN,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ConsentDialog
        open={showCookieDialog}
        onAccept={handleCookieConsent}
        onAlternative={handleAlternativeContact}
        currentLang={currentLang}
        i18n={i18n}
      />
      <PageContainer
        className="contact-page"
        title={getPageTitle()}
        description={getPageDescription()}
      >
        <p
          style={{
            fontSize: "var(--base-font-size)",
            lineHeight: "var(--base-line-height)",
          }}
        >
          {getPageDescription()}
        </p>
        <Container
          maxWidth={false}
          sx={{
            "&.MuiContainer-root": {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 2,
              paddingBottom: 4,
            },
          }}
        >
          <Paper elevation={0}>
            <form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    required
                    id="name"
                    name="name"
                    label={getFieldLabel("name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    required
                    id="email"
                    name="email"
                    label={getFieldLabel("email")}
                    type="email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    required
                    id="phone"
                    name="phone"
                    label={getFieldLabel("phone")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    id="message"
                    name="message"
                    label={getFieldLabel("message")}
                    multiline
                    rows={8}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "none" }}>
                  <TextField
                    fullWidth
                    id="website"
                    name="website"
                    tabIndex={-1}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        id="consent"
                        name="consent"
                        checked={consentChecked}
                        onChange={handleConsentChange}
                      />
                    }
                    label={getFieldLabel("consent")}
                    sx={{
                      ".MuiFormControlLabel-asterisk": {
                        display: "none",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {cookieConsent && (
                    <GoogleReCaptchaProvider reCaptchaKey={getRecaptchaKey()}>
                      <GoogleReCaptcha
                        onVerify={handleRecaptchaChange}
                        refreshReCaptcha={refreshReCaptcha}
                      />
                    </GoogleReCaptchaProvider>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isSubmitting}
                    sx={{
                      mt: 2,
                      position: "relative",
                      "&.Mui-disabled": {
                        backgroundColor: "var(--color-background-dark)",
                        color: "var(--color-text-light)",
                      },
                      minWidth: "auto",
                    }}
                  >
                    <span
                      style={{
                        visibility: isSubmitting ? "hidden" : "visible",
                      }}
                    >
                      {getFieldLabel("submit")}
                    </span>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                          color: "inherit",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </PageContainer>
      <SubmissionResultDialog
        open={!!submissionResult}
        onClose={handleCloseDialog}
        onReset={handleResetForm}
        submissionResult={submissionResult}
        currentLang={currentLang}
        i18n={i18n}
      />
    </ThemeProvider>
  );
};

export default ContactPage;
