import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

interface HeaderProps {
  logoUrl: string;
  logoAlt: string;
  menuItems: {
    id: number;
    title: string;
    link: string;
  }[];
  currentLanguage: "cs" | "en" | "uk";
  onLanguageChange: (lang: "cs" | "en" | "uk") => void;
}

const Header: React.FC<HeaderProps> = ({
  logoUrl,
  logoAlt,
  menuItems,
  currentLanguage,
  onLanguageChange,
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isNavOpen]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const LanguageSwitcher = () => (
    <div className={styles.languageSwitcher}>
      <button
        className={`${styles.languageButton} ${
          currentLanguage === "cs" ? styles.active : ""
        }`}
        onClick={() => onLanguageChange("cs")}
      >
        CZ
      </button>
      <button
        className={`${styles.languageButton} ${
          currentLanguage === "en" ? styles.active : ""
        }`}
        onClick={() => onLanguageChange("en")}
      >
        EN
      </button>
      <button
        className={`${styles.languageButton} ${
          currentLanguage === "uk" ? styles.active : ""
        }`}
        onClick={() => onLanguageChange("uk")}
      >
        UA
      </button>
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logoUrl} alt={logoAlt} className={styles.logoImage} />
        </Link>
      </div>

      <nav className={`${styles.nav} ${isNavOpen ? styles.navOpen : ""}`}>
        <button
          className={styles.closeButton}
          onClick={() => setIsNavOpen(false)}
          aria-label="Close menu"
        >
          <CloseIcon />
        </button>
        <LanguageSwitcher />
        <ul className={styles.navList}>
          {menuItems.map((item, index, arr) => (
            <li key={item.id}>
              <Link
                to={
                  index === arr.length - 1
                    ? `#${item.link.replace("/", "")}`
                    : `/#${item.link.replace("/", "")}`
                }
                className={styles.navLink}
                onClick={() => setIsNavOpen(false)}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <LanguageSwitcher />

      <button
        className={styles.hamburger}
        onClick={() => setIsNavOpen(!isNavOpen)}
        aria-label="Toggle menu"
      >
        <MenuIcon />
      </button>
    </header>
  );
};

export default Header;
