import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material";

interface CookieTableProps {
  title: string;
  category: string;
  publisher: string;
  type: string;
  duration: string;
  currentLang: string;
  i18n: any;
}

const CookieTable: React.FC<CookieTableProps> = ({
  title,
  category,
  publisher,
  type,
  duration,
  currentLang,
  i18n,
}) => {
  return (
    <>
      <Typography variant="h3">{title}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{i18n.cookieTab.category[currentLang]}</TableCell>
            <TableCell sx={{ fontStyle: "italic" }}>{category}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{i18n.cookieTab.publisher[currentLang]}</TableCell>
            <TableCell sx={{ fontStyle: "italic" }}>{publisher}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{i18n.cookieTab.type[currentLang]}</TableCell>
            <TableCell sx={{ fontStyle: "italic" }}>{type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {i18n.cookieTab.validityPeriod[currentLang]}
              <Tooltip title={i18n.cookieTab.note[currentLang]}>
                <span style={{ cursor: "help" }}>*</span>
              </Tooltip>
            </TableCell>
            <TableCell sx={{ fontStyle: "italic" }}>{duration}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default CookieTable;
