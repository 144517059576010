import { Helmet } from "react-helmet";
import { SITE_NAME, SITE_SLOGAN } from "@/constants";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { useContext } from "react";

interface SEOProps {
  title: string;
  description: string;
  canonical?: string;
  type?: string;
  isHomePage?: boolean;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  canonical = window.location.href,
  type = "website",
  isHomePage = false,
}) => {
  const { language } = useContext(HomepageContext);
  const currentLang = (language || "cs") as keyof typeof SITE_SLOGAN;

  const fullTitle = isHomePage
    ? `${SITE_NAME} - ${SITE_SLOGAN[currentLang]}`
    : `${title} | ${SITE_NAME}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="max-image-preview:large" />
      <link rel="canonical" href={canonical} />

      {/* OpenGraph tags */}
      <meta property="og:locale" content="cs_CZ" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonical} />
      <meta property="og:site_name" content={SITE_NAME} />

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: SITE_NAME,
          url: window.location.origin,
          description: description,
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
