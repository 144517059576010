import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { SectionTitle } from "@/components";
import { getMenuItemId } from "@/utils/menuItems";
import { generateProductAnchorId } from "@/utils/productAnchors";
import styles from "./ProductSection.module.css";

export const ProductSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { products_section } = data;

  const productMenuItem = getMenuItemId(data.page_header.main_menu_items, 1);

  const productAnchorBase = products_section.products_section_title_h2;

  return (
    <section id={productMenuItem} className={styles.productSection}>
      <div className={styles.titleContainer}>
        <SectionTitle title={products_section.products_section_title_h2} />
      </div>
      <div className={styles.grid}>
        <a
          href={
            products_section.grid_ups.grid_ups_link
              ? `#${generateProductAnchorId(
                  products_section.grid_ups.grid_ups_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_ups.grid_ups_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_ups.grid_ups_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_ups.grid_ups_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_ups.grid_ups_graphic.url
                : products_section.grid_ups.grid_ups_graphic.sizes.original ??
                  products_section.grid_ups.grid_ups_graphic.sizes.large ??
                  products_section.grid_ups.grid_ups_graphic.sizes.medium
            }
            alt={products_section.grid_ups.grid_ups_graphic_alt}
            width={products_section.grid_ups.grid_ups_graphic.dimensions.width}
            height={
              products_section.grid_ups.grid_ups_graphic.dimensions.height
            }
            className={styles.boxImage}
          />
        </a>
        <a
          href={
            products_section.grid_gensets.grid_gensets_link
              ? `#${generateProductAnchorId(
                  products_section.grid_gensets.grid_gensets_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_gensets.grid_gensets_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_gensets.grid_gensets_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_gensets.grid_gensets_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_gensets.grid_gensets_graphic.url
                : products_section.grid_gensets.grid_gensets_graphic.sizes
                    .original ??
                  products_section.grid_gensets.grid_gensets_graphic.sizes
                    .large ??
                  products_section.grid_gensets.grid_gensets_graphic.sizes
                    .medium
            }
            alt={products_section.grid_gensets.grid_gensets_graphic_alt}
            width={
              products_section.grid_gensets.grid_gensets_graphic.dimensions
                .width
            }
            height={
              products_section.grid_gensets.grid_gensets_graphic.dimensions
                .height
            }
            className={styles.boxImage}
          />
        </a>
        <a
          href={
            products_section.grid_solar.grid_solar_link
              ? `#${generateProductAnchorId(
                  products_section.grid_solar.grid_solar_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_solar.grid_solar_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_solar.grid_solar_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_solar.grid_solar_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_solar.grid_solar_graphic.url
                : products_section.grid_solar.grid_solar_graphic.sizes
                    .original ??
                  products_section.grid_solar.grid_solar_graphic.sizes.large ??
                  products_section.grid_solar.grid_solar_graphic.sizes.medium
            }
            alt={products_section.grid_solar.grid_solar_graphic_alt}
            width={
              products_section.grid_solar.grid_solar_graphic.dimensions.width
            }
            height={
              products_section.grid_solar.grid_solar_graphic.dimensions.height
            }
            className={styles.boxImage}
          />
        </a>
        <a
          href={
            products_section.grid_battery.grid_battery_link
              ? `#${generateProductAnchorId(
                  products_section.grid_battery.grid_battery_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_battery.grid_battery_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_battery.grid_battery_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_battery.grid_battery_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_battery.grid_battery_graphic.url
                : products_section.grid_battery.grid_battery_graphic.sizes
                    .original ??
                  products_section.grid_battery.grid_battery_graphic.sizes
                    .large ??
                  products_section.grid_battery.grid_battery_graphic.sizes
                    .medium
            }
            alt={products_section.grid_battery.grid_battery_graphic_alt}
            width={
              products_section.grid_battery.grid_battery_graphic.dimensions
                .width
            }
            height={
              products_section.grid_battery.grid_battery_graphic.dimensions
                .height
            }
            className={styles.boxImage}
          />
        </a>
        <a
          href={
            products_section.grid_gas.grid_gas_link
              ? `#${generateProductAnchorId(
                  products_section.grid_gas.grid_gas_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_gas.grid_gas_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_gas.grid_gas_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_gas.grid_gas_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_gas.grid_gas_graphic.url
                : products_section.grid_gas.grid_gas_graphic.sizes.original ??
                  products_section.grid_gas.grid_gas_graphic.sizes.large ??
                  products_section.grid_gas.grid_gas_graphic.sizes.medium
            }
            alt={products_section.grid_gas.grid_gas_graphic_alt}
            width={products_section.grid_gas.grid_gas_graphic.dimensions.width}
            height={
              products_section.grid_gas.grid_gas_graphic.dimensions.height
            }
            className={styles.boxImage}
          />
        </a>
        <a
          href={
            products_section.grid_solutions.grid_solutions_link
              ? `#${generateProductAnchorId(
                  products_section.grid_solutions.grid_solutions_link,
                  productAnchorBase
                )}`
              : ""
          }
          className={styles.box}
          onClick={(e) => {
            if (!products_section.grid_solutions.grid_solutions_link) {
              e.preventDefault();
            }
          }}
        >
          <h3 className={styles.boxTitle}>
            {products_section.grid_solutions.grid_solutions_heading_h3}
          </h3>
          <img
            src={
              products_section.grid_solutions.grid_solutions_graphic.type ===
              "image/svg+xml"
                ? products_section.grid_solutions.grid_solutions_graphic.url
                : products_section.grid_solutions.grid_solutions_graphic.sizes
                    .original ??
                  products_section.grid_solutions.grid_solutions_graphic.sizes
                    .large ??
                  products_section.grid_solutions.grid_solutions_graphic.sizes
                    .medium
            }
            alt={products_section.grid_solutions.grid_solutions_graphic_alt}
            width={
              products_section.grid_solutions.grid_solutions_graphic.dimensions
                .width
            }
            height={
              products_section.grid_solutions.grid_solutions_graphic.dimensions
                .height
            }
            className={styles.boxImage}
          />
        </a>
      </div>
    </section>
  );
};
