import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import {
  SectionTitle,
  RichTextContent,
  PropertiesBox,
  ImageWithSrcSet,
  SectionContainer,
  AnimatedInView,
} from "@/components";
import styles from "./BatterySection.module.css";

export const BatterySection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { battery_section } = data;

  return (
    <SectionContainer className={styles.section}>
      <SectionTitle title={battery_section.battery_section_title_h2} />
      <div className={styles.content}>
        <div className={styles.textContent}>
          <RichTextContent
            content={battery_section.battery_section_desc}
            className={styles.description}
          />
          <PropertiesBox content={battery_section.battery_section_props} />
        </div>
        <AnimatedInView className={styles.imageWrapper}>
          <ImageWithSrcSet
            image={battery_section.battery_section_img}
            alt={battery_section.battery_section_img_alt}
            className={styles.image}
          />
        </AnimatedInView>
      </div>
    </SectionContainer>
  );
};
