import styles from "./PropertiesBox.module.css";
import { RichTextContent } from "@/components";

interface PropertiesBoxProps {
  content: string;
  className?: string;
}

export const PropertiesBox: React.FC<PropertiesBoxProps> = ({
  content,
  className,
}) => {
  return (
    <div className={`${styles.propertiesBox} ${className || ""}`}>
      <RichTextContent content={content} />
    </div>
  );
};
