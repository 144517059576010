import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import styles from "./HeroSection.module.css";

export const HeroSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { hero_section } = data;
  const bgImageUrl =
    hero_section.hero_bg_picture.type === "image/svg+xml"
      ? hero_section.hero_bg_picture.url
      : hero_section.hero_bg_picture.sizes.original ??
        hero_section.hero_bg_picture.sizes.large ??
        hero_section.hero_bg_picture.sizes.medium;

  return (
    <section
      className={styles.heroSection}
      style={{ backgroundImage: `url(${bgImageUrl})` }}
      aria-label={hero_section.hero_bg_picture_alt_text}
    >
      <div className={styles.countersContainer}>
        <div className={styles.counterColumn}>
          <h2>{hero_section.hero_counter_one.hero_counter_one_h2}</h2>
          <h3>{hero_section.hero_counter_one.hero_counter_one_h3}</h3>
        </div>
        <div className={styles.counterColumn}>
          <h2>{hero_section.hero_counter_two.hero_counter_two_h2}</h2>
          <h3>{hero_section.hero_counter_two.hero_counter_two_h3}</h3>
        </div>
        <div className={styles.counterColumn}>
          <h2>{hero_section.hero_counter_three.hero_counter_three_h2}</h2>
          <h3>{hero_section.hero_counter_three.hero_counter_three_h3}</h3>
        </div>
      </div>
    </section>
  );
};
