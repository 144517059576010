import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CareerPage from "./pages/career/CareerPage";
import JobDetails from "./pages/career/JobDetails";
import ContactPage from "./pages/contact/ContactPage";
import ConnectedHeader from "./components/header/ConnectedHeader";
import Footer from "./components/footer/Footer";
import { ScrollToTop } from "@/components";
import { BASE_PATH, ROUTES } from "@/config/routes";
import "./styles/global.css";

const ScrollToAnchor: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return null;
};

const App: React.FC = () => {
  const { HOME, CAREER, CONTACT } = ROUTES;

  return (
    <BrowserRouter basename={BASE_PATH}>
      <ScrollToAnchor />
      <ConnectedHeader>
        <main>
          <Routes>
            <Route path={HOME} element={<HomePage />} />
            <Route path={CAREER} element={<CareerPage />} />
            <Route path={`${CAREER}/:slug`} element={<JobDetails />} />
            <Route path={CONTACT} element={<ContactPage />} />
          </Routes>
        </main>
        <Footer />
      </ConnectedHeader>
      <ScrollToTop />
    </BrowserRouter>
  );
};

export default App;
