export const removeDiacritics = (str: string): string => {
  // Transliteration map for Ukrainian Cyrillic characters
  const ukrainianToLatin: { [key: string]: string } = {
    а: "a",
    б: "b",
    в: "v",
    г: "h",
    ґ: "g",
    д: "d",
    е: "e",
    є: "ye",
    ж: "zh",
    з: "z",
    и: "y",
    і: "i",
    ї: "yi",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ь: "",
    ю: "yu",
    я: "ya",
  };

  // First transliterate Ukrainian characters and handle other diacritics and spaces
  const transliterated = str
    .toLowerCase()
    .split("")
    .map((char) => ukrainianToLatin[char] || char)
    .join("")
    .normalize("NFD")
    .replace(/[\u0300-\u036f\u2019]/g, "")
    .replace(/\s+/g, "-");

  // Then encode the transliterated string
  return encodeURIComponent(transliterated);
};

export const getMenuItemId = (menuItemsHtml: string, index: number): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(menuItemsHtml, "text/html");
  const text = doc.querySelectorAll("li")[index].textContent || "";
  return removeDiacritics(text.toLowerCase());
};
