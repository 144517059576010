import styles from "./RichTextContent.module.css";

interface RichTextContentProps {
  content: string;
  className?: string;
}

export const RichTextContent: React.FC<RichTextContentProps> = ({
  content,
  className,
}) => (
  <div
    className={`${styles.richTextContent} ${className}`}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);
