import React, { useContext, useEffect } from "react";
import SEO from "@/components/SEO";
import {
  HeroSection,
  ProductSection,
  AboutSection,
  UpsSection,
  GensetsSection,
  BatteryStorageSection,
  BatterySection,
  SolarSection,
  ServiceSection,
  CareerSection,
  TrustedBySection,
} from "@/components/sections";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { i18n } from "./homepage.i18n";

const HomePage: React.FC = () => {
  const { language } = useContext(HomepageContext);
  const currentLang = (language || "cs") as keyof typeof i18n.pageTitle;

  useEffect(() => {
    const shouldScrollToBottom = sessionStorage.getItem("scrollToBottom");
    if (shouldScrollToBottom) {
      sessionStorage.removeItem("scrollToBottom");
      setTimeout(() => {
        const contactsLink = document.querySelector(
          "header nav ul li:last-child a"
        );
        if (contactsLink) {
          (contactsLink as HTMLElement).click();
        }
      }, 500); // Add half second delay
    }
  }, []);

  return (
    <div className="home-page">
      <SEO
        title={i18n.pageTitle[currentLang]}
        description={i18n.pageDescription[currentLang]}
        isHomePage={true}
      />
      <HeroSection />
      <ProductSection />
      <AboutSection />
      <UpsSection />
      <GensetsSection />
      <BatteryStorageSection />
      <BatterySection />
      <SolarSection />
      <ServiceSection />
      <CareerSection />
      <TrustedBySection />
    </div>
  );
};

export default HomePage;
