import React, { useContext } from "react";
import { PageContainer } from "@/components";
import JobList from "./JobList";
import { HomepageContext } from "@/components/header/ConnectedHeader";

const CareerPage: React.FC = () => {
  const { language } = useContext(HomepageContext);

  const getPageTitle = () => {
    switch (language) {
      case "uk":
        return "Вакансії";
      case "en":
        return "Career Opportunities";
      default:
        return "Nabídka volných pozic";
    }
  };

  const getPageDescription = () => {
    switch (language) {
      case "uk":
        return "Дізнайтеся про можливості працевлаштування в нашій компанії. Приєднуйтесь до нашої команди експертів у сфері систем безперебійного живлення.";
      case "en":
        return "Explore exciting career opportunities with us. Join our team of experts in providing innovative UPS systems and power backup solutions.";
      default:
        return "Prozkoumejte pracovní příležitosti v naší společnosti. Připojte se k našemu týmu expertů v oblasti UPS systémů a záložních zdrojů.";
    }
  };

  return (
    <PageContainer
      className="career-page"
      title={getPageTitle()}
      description={getPageDescription()}
    >
      <JobList />
    </PageContainer>
  );
};

export default CareerPage;
