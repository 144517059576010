import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { SITE_NAME } from "@/constants";
import axios from "axios";
import { PageContainer, RichTextContent } from "@/components";
import styles from "./JobDetails.module.css";
import { ROUTES } from "@/config/routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HomepageContext } from "@/components/header/ConnectedHeader";

interface JobPosition {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  slug: string;
}

const i18n = {
  cs: "Seznam pozic",
  en: "Job listings",
  uk: "Список вакансій",
};

const JobDetails: React.FC = () => {
  const [jobPosition, setJobPosition] = useState<JobPosition | null>(null);
  const { slug } = useParams();
  const { language } = useContext(HomepageContext);

  useEffect(() => {
    const fetchJobPosition = async () => {
      try {
        const response = await axios.get(
          `https://upstechnology.cz/cms/wp-json/wp/v2/jobs?slug=${slug}`
        );
        setJobPosition(response.data[0]);
      } catch (error) {
        console.error("Error fetching job position:", error);
      }
    };

    if (slug) {
      fetchJobPosition();
    }
  }, [slug]);

  if (!jobPosition) {
    return <div>Loading...</div>;
  }

  return (
    <PageContainer
      className="job-details-page"
      title={jobPosition.title.rendered}
      description={`${jobPosition.title.rendered} at ${SITE_NAME}`}
      type="article"
    >
      <div className={styles.jobContent}>
        <RichTextContent content={jobPosition.content.rendered} />
        <div className={styles.backButtonWrapper}>
          <Link to={ROUTES.CAREER} className={styles.backButton}>
            <ArrowBackIcon />
            <span>{i18n[language]}</span>
          </Link>
        </div>
      </div>
    </PageContainer>
  );
};

export default JobDetails;
