import { Button, ButtonProps } from "@mui/material";

export const DialogButton: React.FC<ButtonProps> = (props) => (
  <Button
    {...props}
    sx={{
      padding: "10px 20px",
      ...props.sx,
    }}
  />
);
