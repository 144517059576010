import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CookieTable from "./CookieTable";
import { DialogButton } from "./DialogButton";
import { cookieList } from "../data/cookieList";
import { TransitionProps } from "@mui/material/transitions";

interface ConsentDialogProps {
  open: boolean;
  onAccept: () => void;
  onAlternative: () => void;
  currentLang: string;
  i18n: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel: React.FC<{
  children?: React.ReactNode;
  value: number;
  index: number;
}> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`consent-tabpanel-${index}`}
      aria-labelledby={`consent-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const ConsentDialog: React.FC<ConsentDialogProps> = ({
  open,
  onAccept,
  onAlternative,
  currentLang,
  i18n,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{i18n.cookieConsent.title[currentLang]}</DialogTitle>
      <DialogContent
        sx={{
          "& .MuiTypography-body1": {
            fontSize: ["0.875rem", "1.25rem"],
          },
          "& .MuiTypography-subtitle2": {
            fontSize: ["1rem", "1.25rem"],
            lineHeight: 1.5,
          },
          "& .MuiTypography-h3": {
            fontSize: ["1rem", "1.375rem"],
            marginBottom: "0.5rem",
          },
          "& .MuiTypography-body2": {
            fontSize: ["0.75rem", "1rem"],
            marginBottom: "1rem",
          },
          "& .MuiTable-root": {
            marginBottom: "1.5rem",
          },
          "& .MuiTableCell-root": {
            fontSize: ["0.75rem", "1rem"],
            padding: "0.5rem",
          },
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="cookie consent tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .Mui-selected": {
              color: "var(--color-primary-dark) !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--color-primary-dark)",
            },
          }}
        >
          <Tab
            label={i18n.cookieConsent.tabs.consent[currentLang]}
            id="consent-tab-0"
          />
          <Tab label="reCAPTCHA" id="consent-tab-1" />
          <Tab label="Cookies" id="consent-tab-2" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Typography variant="body1">
            {i18n.cookieConsent.message[currentLang]}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
            {i18n.cookieConsent.declaration[currentLang]}
          </Typography>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography variant="h3">
            {i18n.recaptchaTab.whatIs.title[currentLang]}
          </Typography>
          <Typography variant="body2">
            {i18n.recaptchaTab.whatIs.content[currentLang]}
          </Typography>
          <Typography variant="h3">
            {i18n.recaptchaTab.whyUse.title[currentLang]}
          </Typography>
          <Typography variant="body2">
            {i18n.recaptchaTab.whyUse.content[currentLang]}
          </Typography>
          <Typography variant="h3">
            {i18n.recaptchaTab.howUse.title[currentLang]}
          </Typography>
          <Typography variant="body2">
            {i18n.recaptchaTab.howUse.content[currentLang]}
          </Typography>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Typography variant="body2">
            {i18n.cookieTab.briefIntro[currentLang]}
          </Typography>

          {cookieList.map((cookie) => (
            <CookieTable
              key={cookie.title}
              title={cookie.title}
              category={cookie.category}
              publisher={cookie.publisher}
              type={cookie.type}
              duration={cookie.duration}
              currentLang={currentLang}
              i18n={i18n}
            />
          ))}

          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              gap: 3,
              mt: 6,
            }}
          >
            <Link
              href={i18n.cookieTab.linkPolicy.url[currentLang]}
              target="_blank"
              rel="noopener"
              sx={{
                color: "var(--color-primary-dark)",
                "&:hover": {
                  color: "var(--color-primary-hover)",
                },
              }}
            >
              {i18n.cookieTab.linkPolicy.text[currentLang]}
            </Link>
            <Link
              href={i18n.cookieTab.linkTerms.url[currentLang]}
              target="_blank"
              rel="noopener"
              sx={{
                color: "var(--color-primary-dark)",
                "&:hover": {
                  color: "var(--color-primary-hover)",
                },
              }}
            >
              {i18n.cookieTab.linkTerms.text[currentLang]}
            </Link>
          </Box>
          <Typography variant="body2" sx={{ mt: 8 }}>
            {i18n.cookieTab.removeCookies[currentLang]}
          </Typography>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onAlternative}>
          {i18n.cookieConsent.alternativeBtn[currentLang]}
        </DialogButton>
        <DialogButton onClick={onAccept} variant="contained">
          {i18n.cookieConsent.acceptBtn[currentLang]}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
