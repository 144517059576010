export const i18n = {
  pageTitle: {
    cs: "UPS Technology - Vaše energetická jistota již 34 let",
    en: "UPS Technology - Your energy security for 34 years",
    uk: "UPS Technology - Ваша енергетична безпека протягом 34 років",
  },
  pageDescription: {
    cs: "Váš spolehlivý partner v energetických řešeních. Dodáváme UPS systémy a záložní napájení pro firmy.",
    en: "Your trusted partner in energy solutions. We provide UPS systems and power backup solutions for businesses.",
    uk: "Ваш надійний партнер у сфері енергетичних рішень. Ми постачаємо системи ДБЖ та резервного живлення для бізнесу.",
  },
};
