import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import {
  SectionTitle,
  RichTextContent,
  ImageWithSrcSet,
  SectionContainer,
  AnimatedInView,
} from "@/components";
import styles from "./CareerSection.module.css";
import { getMenuItemId } from "@/utils";
import { Link } from "react-router-dom";
import { ROUTES } from "@/config/routes";

export const CareerSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { career_section } = data;

  const careerMenuItem = getMenuItemId(data.page_header.main_menu_items, 4);

  return (
    <SectionContainer id={careerMenuItem} className={styles.section}>
      <SectionTitle title={career_section.career_section_title_h2} />
      <div className={styles.content}>
        <div className={styles.textContent}>
          <RichTextContent
            content={career_section.career_section_desc}
            className={styles.description}
          />
          <div className={styles.buttonWrapper}>
            <Link to={ROUTES.CAREER} className={styles.ctaButton}>
              {career_section.career_section_btn}
            </Link>
          </div>
        </div>
        <AnimatedInView className={styles.imageWrapper}>
          <ImageWithSrcSet
            image={career_section.career_section_img}
            alt={career_section.career_section_img_alt}
            className={styles.image}
          />
        </AnimatedInView>
      </div>
    </SectionContainer>
  );
};
