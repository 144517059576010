import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { generateProductAnchorId } from "@/utils";
import {
  SectionTitle,
  RichTextContent,
  PropertiesBox,
  ImageWithSrcSet,
  SectionContainer,
  AnimatedInView,
} from "@/components";
import styles from "./BatteryStorageSection.module.css";

export const BatteryStorageSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { battery_storage_section, products_section } = data;
  const anchorId = generateProductAnchorId(
    products_section.grid_battery.grid_battery_link,
    products_section.products_section_title_h2
  );

  return (
    <SectionContainer id={anchorId} className={styles.section}>
      <SectionTitle
        title={battery_storage_section.battery_storage_section_title_h2}
      />
      <div className={styles.content}>
        <AnimatedInView className={styles.imageWrapper}>
          <ImageWithSrcSet
            image={battery_storage_section.battery_storage_section_img}
            alt={battery_storage_section.battery_storage_section_img_alt}
            className={styles.image}
          />
        </AnimatedInView>
        <div className={styles.textContent}>
          <RichTextContent
            content={battery_storage_section.battery_storage_section_desc}
            className={styles.description}
          />
          <PropertiesBox
            content={battery_storage_section.battery_storage_section_props}
          />
        </div>
      </div>
    </SectionContainer>
  );
};
