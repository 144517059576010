import { Image } from "@/types/homepage";
import { getImageSrcSet } from "@/utils";

interface ImageWithSrcSetProps {
  image: Image;
  alt: string;
  title?: string;
  className?: string;
  sizes?: string;
}

export const ImageWithSrcSet: React.FC<ImageWithSrcSetProps> = ({
  image,
  alt,
  title,
  className,
  sizes = "(max-width: 600px) 90vw, (max-width: 960px) 60vw, (max-width: 1280px) 50vw, 40vw",
}) => (
  <img
    src={
      image.type === "image/svg+xml"
        ? image.url
        : image.sizes.full ??
          image.sizes.hd ??
          image.sizes.xl ??
          image.sizes.large ??
          image.sizes.original
    }
    srcSet={getImageSrcSet(image)}
    sizes={sizes}
    alt={alt}
    title={title || alt}
    loading="eager"
    className={className}
  />
);
