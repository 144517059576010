interface SectionContainerProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
}

export const SectionContainer: React.FC<SectionContainerProps> = ({
  id,
  className,
  children,
}) => (
  <section id={id} className={className}>
    <div className="container">{children}</div>
  </section>
);
