import React, { useContext } from "react";
import { HomepageContext } from "@/components/header/ConnectedHeader";
import { SectionTitle, RichTextContent, SectionContainer } from "@/components";
import { getMenuItemId } from "@/utils/menuItems";
import styles from "./AboutSection.module.css";

export const AboutSection: React.FC = () => {
  const { data } = useContext(HomepageContext);

  if (!data) return null;

  const { about_section } = data;

  const aboutMenuItem = getMenuItemId(data.page_header.main_menu_items, 0);

  return (
    <SectionContainer id={aboutMenuItem} className={styles.section}>
      <SectionTitle title={about_section.about_section_title_h2} />

      <div className={styles.content}>
        <div className={styles.textColumn}>
          <RichTextContent content={about_section.about_section_intro} />
        </div>

        <div className={styles.bannerColumn}>
          <div className={styles.banner}>
            <h3 className={styles.bannerTitle}>
              {about_section.about_banner.about_banner_heading_h3}
            </h3>
            <RichTextContent
              content={about_section.about_banner.about_banner_list}
              className={styles.bannerList}
            />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};
